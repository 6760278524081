<app-block-overview-graph
  #blockGraph
  [isLoading]="isLoading$ | async"
  [resolution]="86"
  [blockLimit]="stateService.blockVSize"
  [orientation]="timeLtr ? 'right' : 'left'"
  [flip]="true"
  [showFilters]="showFilters"
  [overrideColors]="overrideColors"
  (txClickEvent)="onTxClick($event)"
></app-block-overview-graph>
