<div class="block-wrapper">
  <div class="block-container">
    <app-block-overview-graph
      #blockGraph
      [isLoading]="false"
      [resolution]="resolution"
      [blockLimit]="stateService.blockVSize"
      [orientation]="'top'"
      [flip]="false"
      [disableSpinner]="true"
      (txClickEvent)="onTxClick($event)"
    ></app-block-overview-graph>
  </div>
</div>
