<div class="container-xl" style="min-height: 335px" [class.widget]="widget" [class.full-height]="!widget">
  <h1 *ngIf="!widget" class="float-left" i18n="master-page.blocks">Accelerations</h1>
  <div *ngIf="!widget && isLoading" class="spinner-border ml-3" role="status"></div>

  <div class="clearfix"></div>

  <div style="min-height: 295px" *ngIf="accelerationList$ | async as accelerations">
    <table *ngIf="!accelerations || accelerations.length; else noData" class="table table-borderless table-fixed">
      <thead>
        <th class="txid text-left" i18n="dashboard.latest-transactions.txid">TXID</th>
        <ng-container *ngIf="pending">
          <th class="fee-rate text-right" i18n="transaction.fee|Transaction fee">Fee Rate</th>
          <th class="bid text-right" i18n="transaction.fee|Transaction fee">Acceleration Bid</th>
          <th class="time text-right" i18n="accelerator.block">Requested</th>
        </ng-container>
        <ng-container *ngIf="!pending">
          <th class="fee text-right" i18n="transaction.fee|Transaction fee">Out-of-band Fee</th>
          <th class="block text-right" i18n="accelerator.block">Block</th>
          <th class="status text-right" i18n="transaction.status|Transaction Status">Status</th>
        </ng-container>
      </thead>
      <tbody *ngIf="accelerations; else skeleton" [style]="isLoading ? 'opacity: 0.75' : ''">
        <tr *ngFor="let acceleration of accelerations; let i= index;">
          <td class="txid text-left">
            <a [routerLink]="['/tx' | relativeUrl, acceleration.txid]">
              <app-truncate [text]="acceleration.txid" [lastChars]="5"></app-truncate>
            </a>
          </td>
          <ng-container *ngIf="pending">
            <td class="fee-rate text-right">
              <app-fee-rate [fee]="acceleration.effectiveFee" [weight]="acceleration.effectiveVsize * 4"></app-fee-rate>
            </td>
            <td class="bid text-right">
              {{ (acceleration.feeDelta) | number }} <span class="symbol" i18n="shared.sat|sat">sat</span>
            </td>
            <td class="time text-right">
              <app-time kind="since" [time]="acceleration.added" [fastRender]="true"></app-time>
            </td>
          </ng-container>
          <ng-container *ngIf="!pending">
            <td *ngIf="acceleration.feePaid" class="fee text-right">
              {{ (acceleration.feePaid) | number }} <span class="symbol" i18n="shared.sat|sat">sat</span>
            </td>
            <td *ngIf="!acceleration.feePaid" class="fee text-right">
              ~
            </td>
            <td class="block text-right">
              <a [routerLink]="['/block' | relativeUrl, acceleration.blockHeight]">{{ acceleration.blockHeight }}</a>
            </td>
            <td class="status text-right">
              <span *ngIf="acceleration.status === 'accelerating'" class="badge badge-warning" i18n="transaction.rbf.mined">Pending</span>
              <span *ngIf="acceleration.status === 'mined' || acceleration.status === 'completed'" class="badge badge-success" i18n="transaction.rbf.mined">Mined</span>
              <span *ngIf="acceleration.status === 'failed'" class="badge badge-danger" i18n="accelerator.canceled">Canceled</span>
            </td>
          </ng-container>
        </tr>
      </tbody>
      <ng-template #skeleton>
        <tbody>
          <tr *ngFor="let item of skeletonLines">
            <td class="txid text-left">
              <span class="skeleton-loader" style="max-width: 75px"></span>
            </td>
            <td class="fee text-right">
              <span class="skeleton-loader" style="max-width: 75px"></span>
            </td>
            <td class="fee-delta text-right">
              <span class="skeleton-loader" style="max-width: 75px"></span>
            </td>
            <td class="status text-right">
              <span class="skeleton-loader" style="max-width: 75px"></span>
            </td>
          </tr>
        </tbody>
      </ng-template>
    </table>

    <ng-template [ngIf]="!widget">
      <div class="clearfix"></div>
      <br>
    </ng-template>
  </div>

  <ng-template #noData>
    <div class="no-data">
      <span *ngIf="pending" i18n="accelerations.no-accelerations">There are no active accelerations</span>
      <span *ngIf="!pending" i18n="accelerations.no-accelerations">There are no recent accelerations</span>
    </div>
  </ng-template>
  
</div>
